<template>
  <div id="app">
    <div v-if="currentType == 'loading'">
      <Confirmation />
    </div>

    <div v-if="currentType == 'car'" :key="index">
      <div
        v-if="url_color"
        class="corner fixed py-4 px-20 flex justify-center items-center"
        :style="urlColor"
      >
        <div class="line-height text-6xl font-semibold flex items-center">
          {{ company_url }}
        </div>
      </div>
      <!-- section one -->
      <div class="flex w-screen h-screen">
        <div class="w-3/4">
          <!-- main img -->
          <div class="h-2/3 relative">
            <div
              v-if="url_color && vehicle && vehicle.displayReference"
              class="corner-left absolute bottom-0 right-0 py-4 px-20 flex justify-center items-center"
              :style="urlColor"
            >
              <div class="line-height text-6xl font-semibold flex items-center">
                {{ vehicle.displayReference }}
              </div>
            </div>

            <div>
              <img
                :src="vehicle.images[0] ? vehicle.images[0] : company_logo"
                alt="vehicle"
                class="w-full h-full mainImageSlide ml-auto mr-auto object-cover z-50"
              />
            </div>
          </div>
          <!-- details -->
          <div
            class="relative h-1/3 flex items-center"
            :style="[backgroundColor, textColor]"
          >
            <div class="w-full flex flex-col px-40">
              <div
                class="w-full mb-6 font-bold text-70 6xl:text-7xl 7xl:text-8xl 11xl:text-9xl 13xl:text-10xl 14xl:text-11xl 15xl:text-12xl 6xl:mb-12 leading-80"
              >
                <div
                  class="w-full truncate -mt-3 text-shadow animate__animated animate__fadeInUp"
                >
                  {{ vehicle.brandLabel }} {{ vehicle.modelLabel }}
                </div>
              </div>

              <div class="flex">
                <div class="w-1/3 animate__animated animate__fadeInUp">
                  <div>
                    <p
                      class="text-86 6xl:text-7xl 7xl:text-8xl 11xl:text-9xl 13xl:text-10xl 14xl:text-11xl 15xl:text-12xl font-bold"
                    >
                      € {{ vehicle.price }}
                    </p>
                    <p
                      class="font-bold text-3xl 6xl:text-4xl 7xl:text-5xl 11xl:text-6xl 13xl:text-7xl 14xl:text-8xl 15xl:text-9xl leading-10"
                    >
                      incl. BTW
                    </p>
                    <p
                      class="text-3xl 6xl:text-4xl 7xl:text-5xl 11xl:text-6xl 13xl:text-7xl 14xl:text-8xl 15xl:text-9xl pt-4 leading-34 hidden"
                    >
                      * Let op geld lenen kost ook geld
                    </p>
                  </div>
                </div>

                <div
                  class="w-1/3 text-3xl 6xl:text-4xl 7xl:text-5xl 11xl:text-6xl 13xl:text-7xl 14xl:text-8xl 15xl:text-9xl animate__animated animate__fadeInUp"
                >
                  <ul class="">
                    <li class="flex items-center">
                      <div class="mr-4" style="width: 45px">
                        <font-awesome-icon
                          :icon="['fal', 'road']"
                          class="text-xl 6xl:text-2xl 7xl:text-3xl"
                        />
                      </div>
                      {{ vehicle.kilometers }} km
                    </li>
                    <li class="flex items-center">
                      <div class="mr-4" style="width: 45px">
                        <font-awesome-icon
                          :icon="['far', 'charging-station']"
                          class="text-xl 6xl:text-2xl 7xl:text-3xl mr-4"
                        />
                      </div>
                      {{ vehicle.fuelType }}
                    </li>
                    <li class="flex items-center">
                      <div class="mr-4" style="width: 45px">
                        <font-awesome-icon
                          :icon="['far', 'tachometer-alt-fast']"
                          class="text-xl 6xl:text-2xl 7xl:text-3xl mr-4"
                        />
                      </div>
                      {{ vehicle.kw }} kw/{{ vehicle.cv }} pk
                    </li>
                    <li class="flex items-center">
                      <div class="mr-4" style="width: 45px">
                        <font-awesome-icon
                          :icon="['far', 'leaf']"
                          class="text-xl 6xl:text-2xl 7xl:text-3xl mr-4"
                        />
                      </div>
                      {{ vehicle.co2 }} g/km, {{ vehicle.euroNorm }}
                    </li>
                    <li class="flex items-center">
                      <div class="mr-4" style="width: 45px">
                        <font-awesome-icon
                          :icon="['far', 'calendar-week']"
                          class="text-xl 6xl:text-2xl 7xl:text-3xl mr-4"
                        />
                      </div>
                      {{ vehicle.firstRegistrationMonth }}/{{
                        vehicle.firstRegistrationYear
                      }}
                    </li>
                  </ul>
                </div>

                <div
                  class="w-1/3 text-3xl 6xl:text-4xl 7xl:text-5xl 11xl:text-6xl 13xl:text-7xl 14xl:text-8xl 15xl:text-9xl"
                >
                  <div>
                    <ul
                      class="list-disc list-inside truncate animate__animated animate__fadeInUp"
                    >
                      <li
                        v-for="eq in vehicle.equipments"
                        class="truncate"
                        style="width: 95%"
                      >
                        {{ eq }}
                      </li>
                    </ul>
                  </div>
                  <!-- list container -->
                </div>
                <!-- w-1/3 -->
              </div>
            </div>
            <!-- w-full flex -->

            <!-- circle ----------------------->
            <div id="container">
              <div id="halfClip" :style="animationDuration">
                <div
                  class="halfCircle"
                  id="clipped"
                  :style="[animationDurationDividedBy2, brandingColor]"
                ></div>
              </div>
              <div
                class="halfCircle"
                id="fixed"
                :style="[animationDuration, brandingColor]"
              ></div>
              <img
                :src="vehicle.images[1] ? vehicle.images[1] : company_logo"
                alt="vehicle"
                class="images imagePrevVehicle animate__animated animate__fadeIn"
              />
            </div>
          </div>
          <!-- h-25% flex center -->
        </div>
        <!-- section one -->

        <!-- section two -->
        <div class="w-1/4">
          <!-- 2nd img -->
          <div class="h-1/3 overflow-hidden image-2-container">
            <img
              :src="vehicle.images[2] ? vehicle.images[2] : company_logo"
              alt="vehicle"
              height="100%"
              class="images secondImageSlide"
            />
          </div>

          <!-- 3rd img -->
          <div class="h-1/3 overflow-hidden image-3-container">
            <img
              :src="vehicle.images[3] ? vehicle.images[3] : company_logo"
              alt="vehicle"
              height="100%"
              class="images thirdImageSlide"
            />
          </div>

          <!-- logo -->
          <div
            class="h-1/3 pl-16 flex flex-col justify-center items-center"
            :style="logoBackground"
          >
            <img class="w-2/3" :src="company_logo" />
          </div>
          <!-- logo -->
        </div>
        <!-- section two -->
      </div>
      <!-- full screen -->
      <div
        v-if="currentType == 'youtube'"
        class="w-screen h-screen flex"
        :key="index"
      >
        <div
          class="w-screen h-screen flex items-center justify-center text-7xl text-white"
        >
          <div id="player_yt"></div>
        </div>
      </div>
      <div v-if="currentType == 'loading'">
        <div
          class="w-screen h-screen flex items-center justify-center text-7xl"
        >
          <font-awesome-icon :icon="['fas', 'spinner']" pulse />
        </div>
      </div>
    </div>
  </div>
  <!-- app -->
</template>

<script>
import Confirmation from "./components/Confirmation";
import axios from "axios";
import { isObject, has, isString, filter, includes, map } from "lodash";

export default {
  name: "App",
  components: {
    Confirmation,
  },
  data() {
    return {
      showStarted: false,
      currentLanguage: "nl",
      vehicles: [],
      images: [],
      company_logo: "",
      logo_background_color: "",
      branding_color: "",
      company_url: "",
      url_color: "",
      background_color: "",
      text_color: "",
      vehicle: null,
      index: 0,
      currentIndex: 0,
      currentItem: null,
      currentType: "loading",
      currentTime: localStorage.getItem("currentTime"),
      deviceCode: null,
    };
  },

  watch: {
    // whenever deviceCode changes, this function will run
    deviceCode(newCode, oldCode) {
      if (newCode || this.vehicles.length < 1) {
        this.fetchVehicles();
      }
    },
  },

  methods: {
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    fetchVehicles(onSuccess) {
      axios
        .get("/vehicles", {
          params: {
            device_code: localStorage.getItem("randomNumber"),
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data.autralis;

          this.vehicles = [];
          for (
            let dealerIndex = 0;
            dealerIndex < data.dealership.length;
            dealerIndex++
          ) {
            for (
              let vehicleIndex = 0;
              vehicleIndex < data.dealership[dealerIndex].vehicle.length;
              vehicleIndex++
            ) {
              this.vehicles.push(
                this.mappedVehicle(
                  data.dealership[dealerIndex].vehicle[vehicleIndex]
                )
              );
            }
          }

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }

          const intervalID = setInterval(() => {
            axios
              .get("/lists", {
                headers: {
                  "Content-Type": "application/json",
                },
                params: {
                  device_code: localStorage.getItem("randomNumber"),
                },
              })
              .then((response) => {
                this.playlist = this.mappedPlaylist(response.data.result);
                this.company_logo = response.data.company_logo;
                this.logo_background_color =
                  response.data.logo_background_color;
                this.branding_color = response.data.branding_color;
                this.company_url = response.data.company_url;
                this.url_color = response.data.url_color;
                this.background_color = response.data.background_color;
                this.text_color = response.data.text_color;
                this.startShow();
                clearInterval(intervalID);
              });
          }, 5000);
        })

        .catch(function (error) {
          console.log(error);
        });
    },

    startShow() {
      if (this.showStarted) return;
      this.showStarted = true;
      this.index = -1;
      const setNextSlide = () => {
        this.index++;
        if (this.index >= this.playlist.length) {
          this.index = 0;
        }
        this.currentItem = this.playlist[this.index];
        this.currentType = this.currentItem.type;
        this.currentLength = this.currentItem.length;

        if (this.currentType == "car") {
          const item = this.vehicles.find(
            (v) => v.reference == this.currentItem.reference
          );

          if (item) {
            this.vehicle = item;
          } else {
            setNextSlide();
            console.log("no vehicle...");
          }
        } else {
        }
      };

      const showNextOne = () => {
        setTimeout(() => {
          setNextSlide();
          showNextOne();
        }, this.currentLength * 1000);
      };

      setNextSlide();
      showNextOne();
    },

    mappedPlaylist(result) {
      const references = this.vehicles.map((v) => v.reference);
      return result.filter((r) => {
        if (r.type != "car") return true;
        return references.includes(r.reference);
      });
    },

    mappedImages(vehicle) {
      const imageSetKey = "naked";
      if (!vehicle?.media?.imagesets) return [];
      const imageSet = vehicle?.media?.imagesets.find(
        (imgSet) => imgSet["@key"] == imageSetKey
      );
      let images = vehicle?.media?.images?.image;

      if (imageSet) {
        images = imageSet.images?.image;
      }
      const imagesPositions = [1, 2, 3, 4];
      return map(
        filter(images, (image) =>
          imagesPositions.includes(parseInt(image?.position))
        ),
        (image) => image?.uri
      );
    },

    mappedEquipments(equipments) {
      const equipmentIndexes = [10, 11, 12, 13, 19];
      const language = this.currentLanguage;
      return map(
        filter(equipments, (eq, index) => includes(equipmentIndexes, index)),
        (eq) => {
          if (isString(eq)) {
            return eq;
          } else {
            if (has(eq, language)) return eq[language];
          }
        }
      );
    },

    mappedVehicle(vehicle) {
      return {
        reference: vehicle["@reference"],
        displayReference: vehicle?.displayReference
          ? vehicle.displayReference
          : "",
        brandLabel: vehicle?.brandLabel ? vehicle.brandLabel : "",
        modelLabel: vehicle?.modelLabel ? vehicle.modelLabel : "",
        price: vehicle?.price?.B2C ? this.formatNumber(vehicle.price.B2C) : "",
        images: this.mappedImages(vehicle),
        kilometers: vehicle.kilometers
          ? this.formatNumber(vehicle.kilometers)
          : "",
        fuelType: has(vehicle.fueltype, this.currentLanguage)
          ? vehicle.fueltype[this.currentLanguage]
          : "",
        kw:
          isObject(vehicle.power) && has(vehicle.power, "KW")
            ? vehicle.power.KW
            : "",
        cv:
          isObject(vehicle.power) && has(vehicle.power, "CV")
            ? vehicle.power.CV
            : "",
        co2: vehicle?.co2 ? vehicle?.co2 : "",
        euroNorm: isString(vehicle.euro)
          ? vehicle.euro
          : isObject(vehicle.euro) && has(vehicle.euro, "#text")
          ? vehicle.euro["#text"]
          : "",
        firstRegistrationMonth: vehicle?.firstRegistration?.month
          ? vehicle.firstRegistration.month
          : "",
        firstRegistrationYear: vehicle?.firstRegistration?.year
          ? vehicle.firstRegistration.year
          : "",
        equipments: vehicle?.equipements?.eq
          ? this.mappedEquipments(vehicle?.equipements?.eq)
          : [],
      };
    },
  },

  computed: {
    animationDuration() {
      return {
        "animation-duration": this.currentLength + "s",
      };
    },
    animationDurationDividedBy2() {
      return {
        "animation-duration": this.currentLength / 2 + "s",
      };
    },
    brandingColor() {
      return {
        "border-top-color": this.branding_color,
        "border-left-color": this.branding_color,
      };
    },
    urlColor() {
      return {
        color: this.url_color,
      };
    },
    backgroundColor() {
      return {
        "background-color": this.background_color,
      };
    },
    textColor() {
      return {
        color: this.text_color,
      };
    },
    logoBackground() {
      return {
        "background-color": this.logo_background_color,
      };
    },
  },

  updated() {
    if (this.currentType === "youtube") {
      var player;
      player = new YT.Player("player_yt", {
        height: window.screen.height,
        width: window.screen.width,
        videoId: this.currentItem.reference,
        events: {
          onReady: onPlayerReady,
        },
      });

      function onPlayerReady(event) {
        event.target.playVideo();
      }
    }
  },

  mounted() {
    this.deviceCode = localStorage.getItem("randomNumber");
    this.fetchVehicles();
    const vehiclesInterval = setInterval(() => {
      if (this.deviceCode && this.vehicles.length < 1) {
        this.fetchVehicles(() => {
          clearInterval(vehiclesInterval);
        });
      }
    }, 5000);

    const currentTime = new Date();
    const timeAfter4Hours = currentTime.getHours() + 4;
    localStorage.setItem("currentTime", currentTime);

    setInterval(() => {
      let timeVal = new Date().getHours();
      if (timeAfter4Hours == timeVal) {
        location.reload();
      }
    }, 1000);
  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
